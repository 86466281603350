/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title, slug }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            twitterSite
            company {
               name
            }
          }
        }
      }
    `
  )

  const metaDescription = !!description ? description : site.siteMetadata?.company.name + site.siteMetadata?.description
  const defaultTitle = site.siteMetadata?.title
  const siteUrl = site.siteMetadata?.siteUrl
  const finalTitle = title ? title : defaultTitle;
  const currentUrl = !!slug ? `${siteUrl}${slug}` : siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={finalTitle}
      titleTemplate={title ? `%s | ${defaultTitle}` : '%s'}
      meta={[
        {
          property: `og:url`,
          content: currentUrl,
        },
        {
          property: `og:title`,
          content: finalTitle,
        },
        {
          property: `twitter:title`,
          content: finalTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: siteUrl + `/ogp.png`,
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:site`,
          content: site.siteMetadata.twitterSite,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
